
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Generals",

  setup() {
    const store = useStore();
    const u = localStorage.getItem("user") as any;
    const user = JSON.parse(u);
    const dataList = [
      {
        name: "iBilling_concepts_cost",
        route: "/billing_concepts_cost",
        icon: "las la-file-invoice-dollar",
        count: "",
        permission: "billing concept: index",
      },
      {
        name: "iBilling_concepts_sale",
        route: "/billing_concepts_sale",
        icon: "las la-file-invoice-dollar",
        count: "",
        permission: "billing concept: index",
      },
      {
        name: "iconceptsCodes",
        route: "/concepts_codes",
        icon: "las la-th-list",
        count: "",
        permission: "billing concept: index",
      },
      {
        name: "iconceptsTypes",
        route: "/concepts_types",
        icon: "las la-th-list",
        count: "",
        permission: "participant type: index",
      },
      {
        name: "icostTypes",
        route: "/cost_types",
        icon: "las la-money-bill",
        count: "",
        permission: "participant type: index",
      },
      {
        name: "icurrency",
        route: "/currencies",
        icon: "las la-euro-sign",
        count: "",
        permission: "participant type: index",
      },
      {
        name: "itaxes",
        route: "/taxes",
        icon: "las la-donate",
        count: "",
        permission: "taxes: index",
      },
      {
        name: "itaxesDetail",
        route: "/taxes_detail",
        icon: "las la-donate",
        count: "",
        permission: "tax detail: index",
      },
      {
        name: "tipaymenttype",
        route: "/paymentTypes",
        icon: "las la-receipt",
        count: "",
        permission: "participant type: index",
      },
      {
        name: "tipaymenttypedetails",
        route: "/paymentTypesDetails",
        icon: "las la-receipt",
        count: "",
        permission: "participant type: index",
      },
      {
        name: "iinternational_codes",
        route: "/international_codes",
        icon: "las la-barcode",
        count: "",
        permission: "participant type: index",
      },
      {
        name: "isubdivision",
        route: "/subdivisions",
        icon: "las la-landmark",
        count: "",
        permission: "participant type: index",
      },
      {
        name: "ibillingseriestype",
        route: "/billing_series_types",
        icon: "las la-donate",
        count: "9",
        permission: "billing series types: index",
      },
      {
        name: "ibillingseries",
        route: "/billing_series",
        icon: "las la-donate",
        count: "1",
        permission: "billing serie: index",
      },
    ];
    const dataObject = ref<[] | any>([]);
    const search = ref("");
    const permissions = ref<[] | any>([]);

    const onSearch = () => {
      dataObject.value = dataList.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const canViewCatalog = (item) => {
      if (JSON.stringify(user) !== "{}") {
        return permissions.value.filter((x) => x.name === item.permission).length > 0;
      }
      return false;
    };

    onMounted(() => {
      store.commit("setLoadingStatus", true);
      ApiService.get(`/api/permissions/me`)
        .then(({ data }) => {
          permissions.value = data;
        })
        .catch((error) => {
          store.commit("setLoadingStatus", false);
          console.log(error);
        });
      setCurrentPageBreadcrumbs("generals", []);
      dataObject.value = dataList;
      ApiService.get("/api/catalogs/general").then(({ data }) => {
        dataObject.value[0].count = data.billing_concept_cost;
        dataObject.value[1].count = data.billing_concept_sale;
        dataObject.value[2].count = data.billing_concept_code;
        dataObject.value[3].count = data.billing_concept_types;
        dataObject.value[4].count = data.cost_types;
        dataObject.value[5].count = data.currencies;
        dataObject.value[6].count = data.taxes;
        dataObject.value[7].count = data.taxes_details;
        dataObject.value[8].count = data.payment_types;
        dataObject.value[9].count = data.payment_types_details;
        dataObject.value[10].count = data.international_codes;
        dataObject.value[11].count = data.subdivisions;
        dataObject.value[12].count = data.billing_series_types;
        dataObject.value[13].count = data.billing_series;
        store.commit("setLoadingStatus", false);
      });
    });

    return {
      dataObject,
      dataList,
      search,
      canViewCatalog,
      onSearch,
      permissions,
    };
  },
});
